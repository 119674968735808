<template>
  <div>
    <div class="Batch-Report flex" v-if="this.batchReport !== null">
      <div class="left-section white card-border card-radius" style="margin-top: 25px">
        <h2 class="secondary-header">Batch Performance</h2>
        <div class="subject-select card-border card-radius">
          <div class="select-data flex">
            <span style="padding-right: 16px" v-click-outside="onClickSelectBatch">
              <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showBatchList = false"
                >Select Batch<i class="material-icons fs-15 black-text" style="margin-left: 8px"
                  >expand_more</i
                ></a
              >
              <h1
                class="selectedBatch fs-16 fw-600 black-text truncate"
                style="margin-top: 4px; width: 136px"
              >
                {{ this.selectedBatch }}
              </h1>
            </span>
            <span class="border_line"></span>
            <span style="padding-left: 16px" v-click-outside="onClickSeledSubject">
              <a
                class="fs-12 fw-600 skyblue-text flex items-center"
                @click="showSubjectList = false"
                >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
                  >expand_more</i
                ></a
              >
              <h1 class="fs-16 fw-600 black-text truncate" style="margin-top: 4px; width: 136px">
                {{ this.selectedSubjectName ? this.selectedSubjectName : " - " }}
              </h1>
            </span>
          </div>
          <div class="subject-list scroll-verticle" v-if="!showBatchList">
            <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showBatchList = true"
              >Select Batch<i class="material-icons fs-15 black-text" style="margin-left: 8px"
                >expand_less</i
              ></a
            >
            <div class="sublist">
              <p
                v-for="(item, index) in batchFilteredData"
                :key="index"
                @click="
                  onselectBatch(
                    item.Name,
                    item.CourseId,
                    item.CenterId,
                    item.AffiliationCourseId,
                    item.ClassId,
                  )
                "
              >
                {{ item.Name }}
              </p>
            </div>
          </div>
          <div class="subject-list scroll-verticle" v-if="!showSubjectList">
            <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showSubjectList = true"
              >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
                >expand_less</i
              ></a
            >
            <div class="sublist">
              <p
                v-for="(item, index) in selectedSubjectforBatch"
                :key="index"
                @click="onselectSubject(item.SubjectId, item.SubjectName)"
              >
                {{ item.SubjectName }}
              </p>
            </div>
          </div>
        </div>
        <div class="chapter-wise-detail">
          <h2 class="secondary-header">Student-wise Details</h2>
          <div
            class="scroll-horizontal flex"
            v-dragscroll
            v-if="Object.keys(this.batchReport.studentWiseCards).length > 0"
          >
            <a
              v-for="(subjectFilter, index) in Object.values(SubjectiveFilterList)"
              :key="index"
              class="gray-btn"
              style="margin-right: 10px"
              :class="subjectFilter.isDefault ? 'active' : ''"
              @click="filterSubjectList(subjectFilter, index)"
              >{{ subjectFilter.displayName }}</a
            >
          </div>
        </div>
        <div class="Student-wise-details" v-click-outside="onClickOutside">
          <div class="SWD-Header" v-if="swdheader">
            <div class="">
              <p style="margin-left: 45px">Name</p>
            </div>
            <div class="flex">
              <p
                class="overallExpertise"
                style="margin-right: 38px; align-items: center; padding-top: 12px"
              >
                Overall Expertise
              </p>
              <input
                class="search-text"
                type="text"
                id="search"
                style="margin-right: 6px; margin-top: 6px; cursor: pointer"
                @click="searchBar"
              />
            </div>
          </div>
          <div class="searchbar" v-if="searchbar">
            <input
              type="text"
              id="search2"
              placeholder="search..."
              v-model="SearchSubjectwiseDetails"
            />
          </div>
          <div class="SWD-body scroll-verticle">
            <div
              class="SWD-body-content"
              v-for="(studentdD, index) in SearchSubWiseList"
              :key="index"
            >
              <div class="flex flex-between" @click="expand(index)" style="cursor: pointer">
                <div class="flex" style="align-items: center">
                  <span v-if="Object.keys(this.batchReport.studentWiseCards).length > 0">
                    <ProfileAvatar
                      :username="studentdD.userName.charAt(0)"
                      class="leader-avatar"
                      :bgColor="leaderBg[index] + '!important'"
                      style="margin-left: 15px; border: none"
                    ></ProfileAvatar>
                  </span>
                  <h3
                    class="truncate fs-12 black-text fw-600"
                    style="white-space: nowrap; width: 85px"
                    v-if="extended !== index"
                  >
                    {{ studentdD.userName }}
                  </h3>
                  <h3
                    class="truncate fs-12 fw-600"
                    style="white-space: nowrap; color: #3751ff; width: 85px"
                    v-if="extended === index"
                  >
                    {{ studentdD.userName }}
                  </h3>
                </div>
                <div class="flex">
                  <div class="progress1" style="margin: auto; display: flex">
                    <div
                      class="progress-bar1"
                      role="progressbar"
                      :style="`width: ${parseInt((studentdD.gaugeValue * 100).toFixed(0))}%`"
                      v-bind:aria-valuenow="parseInt((studentdD.gaugeValue * 100).toFixed(0))"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :class="[
                        {
                          transparent:
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) == 0 ||
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) == '',
                        },
                        {
                          red:
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) <= 50 &&
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) > 0,
                        },
                        {
                          green:
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) <= 100 &&
                            parseInt((studentdD.gaugeValue * 100).toFixed(0)) > 50,
                        },
                      ]"
                    ></div>
                  </div>
                  <div style="margin-left: 24px">
                    <i
                      v-if="extended === index"
                      class="material-icons fs-15 black-text"
                      :id="index"
                      :value="index"
                      style="cursor: pointer"
                      >expand_less</i
                    >
                    <i
                      v-if="extended !== index"
                      class="material-icons fs-15 black-text"
                      :id="index"
                      :value="index"
                      style="cursor: pointer"
                      >expand_more</i
                    >
                  </div>
                </div>
              </div>
              <div class="dropdown_content" v-if="extended === index">
                <div
                  class="max-width: 238px; width: 100%;"
                  style="padding-left: 40px; padding-top: 21px"
                >
                  <div style="display: flex">
                    <div>
                      <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.0025 14.9257C13.3929 14.4406 15.999 11.5248 15.999 8.00025C15.999 4.13628 12.8667 1.00391 9.00269 1.00391C5.13872 1.00391 2.00635 4.13628 2.00635 8.00025C2.00635 11.2469 4.21774 13.977 7.21644 14.7665L10.0025 16.9953V14.9257ZM11.1935 9.09031C11.3568 8.76262 11.4487 8.39307 11.4487 8.00208C11.4487 6.65276 10.3548 5.55891 9.0055 5.55891C7.65617 5.55891 6.56233 6.65276 6.56233 8.00208C6.56233 9.3514 7.65617 10.4452 9.0055 10.4452C9.54604 10.4452 10.0456 10.2697 10.4502 9.97252L9.01533 8.82461C8.76666 8.62567 8.72634 8.26281 8.92528 8.01414C9.12421 7.76547 9.48707 7.72515 9.73575 7.92409L11.1935 9.09031ZM12.162 9.86512C12.4849 9.31921 12.6702 8.68229 12.6702 8.00208C12.6702 5.97809 11.0295 4.33733 9.0055 4.33733C6.98151 4.33733 5.34075 5.97809 5.34075 8.00208C5.34075 10.0261 6.98151 11.6668 9.0055 11.6668C9.93339 11.6668 10.7807 11.322 11.4264 10.7535L11.8984 11.1311C12.1471 11.33 12.5099 11.2897 12.7089 11.041C12.9078 10.7924 12.8675 10.4295 12.6188 10.2306L12.162 9.86512Z"
                            :fill="studentdD.Attempted.color"
                            style="margin-right: 7px"
                          />
                        </svg>
                        <span
                          class="subjectWiseDetails_subdata"
                          :style="`color: ${studentdD.Attempted.color}`"
                          style="width: 76px"
                          >{{ studentdD.Attempted.value }}</span
                        >
                      </p>
                    </div>
                    <div>
                      <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px"
                        >
                          <path
                            d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                            :fill="studentdD.TimeTaken.color"
                          />
                          <path
                            d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                            :fill="studentdD.TimeTaken.color"
                            style="margin-right: 7px"
                          />
                        </svg>
                        <span
                          class="subjectWiseDetails_subdata"
                          :style="`color: ${studentdD.TimeTaken.color}`"
                          style="width: 86px"
                          >{{ studentdD.TimeTaken.value }}</span
                        >
                      </p>
                    </div>
                    <div>
                      <p>
                        <svg
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 -1.0411e-06C0.947715 -9.92815e-07 0.499999 0.447715 0.499999 0.999999L0.5 13C0.5 13.5523 0.947715 14 1.5 14L10.5 14C11.0523 14 11.5 13.5523 11.5 13L11.5 0.999999C11.5 0.447715 11.0523 -9.2251e-07 10.5 -8.74228e-07L5.5 -1.39079e-06L5.5 4.51716C5.5 4.69534 5.28457 4.78457 5.15858 4.65858L4 3.5L2.84142 4.65858C2.71543 4.78457 2.5 4.69534 2.5 4.51716L2.5 -1.12852e-06L1.5 -1.0411e-06ZM2.5 7.5C2.5 7.22386 2.72386 7 3 7L9 7C9.27614 7 9.5 7.22386 9.5 7.5C9.5 7.77614 9.27614 8 9 8L3 8C2.72386 8 2.5 7.77614 2.5 7.5ZM3 10C2.72386 10 2.5 10.2239 2.5 10.5C2.5 10.7761 2.72386 11 3 11L7 11C7.27614 11 7.5 10.7761 7.5 10.5C7.5 10.2239 7.27614 10 7 10L3 10Z"
                            :fill="studentdD.Chapters.color"
                          />
                        </svg>
                        <span
                          class="subjectWiseDetails_subdata"
                          :style="`color: ${studentdD.Chapters.color}`"
                          >{{ studentdD.Chapters.value }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="flex" style="margin-top: 11px">
                    <div>
                      <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px"
                        >
                          <path
                            d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                            :fill="studentdD.Accuracy.color"
                          />
                        </svg>
                        <span
                          class="subjectWiseDetails_subdata"
                          :style="`color: ${studentdD.Accuracy.color}`"
                          style="width: 75px"
                          >{{ studentdD.Accuracy.value }}</span
                        >
                      </p>
                    </div>
                    <div>
                      <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 8px"
                        >
                          <path
                            d="M9.00078 16.0312C12.4602 16.0312 15.2727 13.2047 15.2727 9.73125C15.2727 8.19844 14.7242 6.77812 13.8102 5.69531L14.1898 5.31562C14.4148 5.09062 14.4148 4.73906 14.1898 4.51406C13.9648 4.28906 13.6133 4.28906 13.3883 4.51406L13.0086 4.89375C12.0664 4.07812 10.8711 3.55781 9.56328 3.44531V3.09375H9.78828C10.0977 3.09375 10.3508 2.84062 10.3508 2.53125C10.3508 2.22187 10.0977 1.96875 9.78828 1.96875H8.21328C7.90391 1.96875 7.65078 2.22187 7.65078 2.53125C7.65078 2.84062 7.90391 3.09375 8.21328 3.09375H8.43828V3.44531C7.13047 3.55781 5.93516 4.07812 4.97891 4.89375L4.59922 4.5C4.37422 4.275 4.02266 4.275 3.79766 4.5C3.57266 4.725 3.57266 5.07656 3.79766 5.30156L4.17734 5.68125C3.26328 6.77813 2.71484 8.18437 2.71484 9.71719C2.72891 13.2047 5.54141 16.0312 9.00078 16.0312ZM9.00078 4.26094C11.9961 4.26094 14.4289 6.70781 14.4289 9.73125C14.4289 12.7547 11.9961 15.1875 9.00078 15.1875C6.00547 15.1875 3.57266 12.7406 3.57266 9.73125C3.57266 6.72187 6.00547 4.26094 9.00078 4.26094Z"
                            :fill="studentdD.AvgTime.color"
                          />
                          <path
                            d="M8.99922 13.9355C11.3195 13.9355 13.1898 12.0512 13.1898 9.7168C13.1898 7.38242 11.3055 5.49805 8.99922 5.49805C6.69297 5.49805 4.80859 7.38242 4.80859 9.7168C4.80859 12.0512 6.67891 13.9355 8.99922 13.9355ZM8.57734 8.73242V6.73555C8.57734 6.49648 8.76016 6.31367 8.99922 6.31367C9.23828 6.31367 9.42109 6.49648 9.42109 6.73555V8.73242C9.80078 8.90117 10.068 9.28086 10.068 9.7168C10.068 10.3074 9.58984 10.7855 8.99922 10.7855C8.40859 10.7855 7.93047 10.3074 7.93047 9.7168C7.93047 9.28086 8.19766 8.90117 8.57734 8.73242Z"
                            :fill="studentdD.AvgTime.color"
                          />
                          <line
                            x1="3"
                            y1="9.5"
                            x2="1"
                            y2="9.5"
                            :stroke="studentdD.AvgTime.color"
                            stroke-linecap="round"
                          />
                          <line
                            x1="7.5"
                            y1="15.5"
                            x2="4.5"
                            y2="15.5"
                            :stroke="studentdD.AvgTime.color"
                            stroke-linecap="round"
                          />
                          <line
                            x1="3.5"
                            y1="12.5"
                            x2="2.5"
                            y2="12.5"
                            :stroke="studentdD.AvgTime.color"
                            stroke-linecap="round"
                          />
                          <line
                            x1="1"
                            y1="12.5"
                            x2="0.5"
                            y2="12.5"
                            :stroke="studentdD.AvgTime.color"
                            stroke-linecap="round"
                          />
                          <line
                            x1="3"
                            y1="15.5"
                            x2="2"
                            y2="15.5"
                            :stroke="studentdD.AvgTime.color"
                            stroke-linecap="round"
                          />
                        </svg>
                        <span
                          class="subjectWiseDetails_subdata"
                          :style="`color: ${studentdD.AvgTime.color}`"
                          >{{ studentdD.AvgTime.value }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-section" style="margin-top: 25px">
        <!-- Overview -->
        <div
          class="middle-card-box white card-radius card-border"
          v-if="this.overviewCardList.length > 0"
        >
          <h2 class="secondary-header">Overview</h2>
          <div class="card-content">
            <div
              class="card-box card-radius card-border"
              v-for="(cardlist, index) in overviewCardList"
              :key="index"
            >
              <h3 class="fs-12 fw-700 black-text">{{ cardlist.title }}</h3>
              <h4 class="fs-20 fw-700">{{ cardlist.value }}</h4>
            </div>
          </div>
        </div>
        <div
          class="middle-Chapter-card-box white card-radius card-border"
          v-click-outside="onClickOustside1"
        >
          <div class="Chapter_wise_Analysis flex flex-between" style="padding: 0px">
            <h2 class="secondary-header" style="height: auto">Chapter-wise Analysis</h2>
            <div
              v-dragscroll
              v-if="Object.keys(this.batchReport.chapterWiseCards).length > 0"
              class="scroll-horizontal flex"
              style="width: 300px"
            >
              <a
                v-for="(chapterFilter, index) in Object.values(chapterwiseFilterList)"
                :key="index"
                class="gray-btn"
                style="margin-right: 10px"
                :class="chapterFilter.isDefault ? 'active' : ''"
                @click="filterChapterCards(chapterFilter, index)"
                >{{ chapterFilter.displayName }}</a
              >
            </div>
          </div>
          <div style="border-bottom: 1px solid #e0e4f0"></div>
          <div>
            <div
              class="chapter_wise_analysis1 flex flex-between items-center"
              v-if="chapterwisedetails"
            >
              <p class="fs-10 fw-400" style="padding-left: 30px; margin-top: 11px">Chapter Name</p>
              <p class="fs-10 fw-400" style="width: 130px; margin-left: 42px; margin-top: 11px">
                Accuracy
              </p>
              <input
                class="search-text"
                type="text"
                id="search"
                style="margin-right: 6px; margin-top: 6px; cursor: pointer; margin-right: 16px"
                @click="searchChapter()"
              />
            </div>
            <div
              class="chapter_wise_card_scroll scroll-verticle"
              style="margin-left: 14px; margin-right: 16px"
            >
              <div
                class="chapter_wise_card"
                v-if="chapterwisesearchdetails"
                style="margin-top: 11px"
              >
                <input
                  type="text"
                  id="search2"
                  placeholder="search..."
                  v-model="SearchChapterWiseDetails"
                />
              </div>
              <div
                class="chapter_wise_card"
                v-for="(card, index) in SearchChapterWiseList"
                :key="index"
                style="cursor: pointer"
              >
                <div
                  class="Chapter_card"
                  :id="index"
                  :value="index"
                  @click="expand1(index, card.chapterId)"
                >
                  <h3 class="fs-12 fw-600 truncate card-name" v-if="extended1 !== index">
                    {{ card.chapterName }}
                  </h3>
                  <h3
                    class="Chapter_name fs-12 fw-600 truncate card-name"
                    v-if="extended1 === index"
                    style="color: #3751ff"
                  >
                    {{ card.chapterName }}
                  </h3>
                  <div class="progress2" style="margin-top: 18px; margin-bottom: 18px">
                    <div
                      class="progress-bar1"
                      role="progressbar"
                      :style="`width: ${parseInt((card.gaugeValue * 100).toFixed(0))}%`"
                      v-bind:aria-valuenow="parseInt((card.gaugeValue * 100).toFixed(0))"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :class="[
                        {
                          transparent:
                            parseInt((card.gaugeValue * 100).toFixed(0)) == 0 ||
                            parseInt((card.gaugeValue * 100).toFixed(0)) == '',
                        },
                        {
                          red:
                            parseInt((card.gaugeValue * 100).toFixed(0)) <= 50 &&
                            parseInt((card.gaugeValue * 100).toFixed(0)) > 0,
                        },
                        {
                          green:
                            parseInt((card.gaugeValue * 100).toFixed(0)) <= 100 &&
                            parseInt((card.gaugeValue * 100).toFixed(0)) > 50,
                        },
                      ]"
                    ></div>
                  </div>
                  <!-- <a class="chapter_performance_card fs-10 fw-400" style="opacity: 0;">strong</a> -->
                  <div class="" style="margin-left: 46px">
                    <i
                      v-if="extended1 === index"
                      class="material-icons fs-15 black-text"
                      :id="index"
                      :value="index"
                      style="cursor: pointer; margin-top: 13px; margin-right: 5px"
                      >expand_less</i
                    >
                    <i
                      v-if="extended1 !== index"
                      class="material-icons fs-15 black-text"
                      :id="index"
                      :value="index"
                      style="cursor: pointer; margin-top: 13px; margin-right: 5px"
                      >expand_more</i
                    >
                  </div>
                </div>
                <div class="chapter_wise_performance_details" v-if="extended1 === index">
                  <div class="chapter_wise_performance_details1">
                    <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 8px"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.0025 14.9257C13.3929 14.4406 15.999 11.5248 15.999 8.00025C15.999 4.13628 12.8667 1.00391 9.00269 1.00391C5.13872 1.00391 2.00635 4.13628 2.00635 8.00025C2.00635 11.2469 4.21774 13.977 7.21644 14.7665L10.0025 16.9953V14.9257ZM11.1935 9.09031C11.3568 8.76262 11.4487 8.39307 11.4487 8.00208C11.4487 6.65276 10.3548 5.55891 9.0055 5.55891C7.65617 5.55891 6.56233 6.65276 6.56233 8.00208C6.56233 9.3514 7.65617 10.4452 9.0055 10.4452C9.54604 10.4452 10.0456 10.2697 10.4502 9.97252L9.01533 8.82461C8.76666 8.62567 8.72634 8.26281 8.92528 8.01414C9.12421 7.76547 9.48707 7.72515 9.73575 7.92409L11.1935 9.09031ZM12.162 9.86512C12.4849 9.31921 12.6702 8.68229 12.6702 8.00208C12.6702 5.97809 11.0295 4.33733 9.0055 4.33733C6.98151 4.33733 5.34075 5.97809 5.34075 8.00208C5.34075 10.0261 6.98151 11.6668 9.0055 11.6668C9.93339 11.6668 10.7807 11.322 11.4264 10.7535L11.8984 11.1311C12.1471 11.33 12.5099 11.2897 12.7089 11.041C12.9078 10.7924 12.8675 10.4295 12.6188 10.2306L12.162 9.86512Z"
                          :fill="card.Attempted.color"
                          style="margin-right: 7px"
                        />
                      </svg>
                      <span :style="`color: ${card.Attempted.color}`" style="margin-right: 45px">{{
                        card.Attempted.value
                      }}</span>
                    </p>
                    <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 8px"
                      >
                        <path
                          d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                          :fill="card.TimeTaken.color"
                        />
                        <path
                          d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                          :fill="card.TimeTaken.color"
                          style="margin-right: 7px"
                        />
                      </svg>
                      <span :style="`color: ${card.TimeTaken.color}`" style="margin-right: 45px">{{
                        card.TimeTaken.value
                      }}</span>
                    </p>
                    <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 8px"
                      >
                        <path
                          d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                          :fill="card.Accuracy.color"
                        />
                      </svg>
                      <span :style="`color: ${card.Accuracy.color}`" style="margin-right: 45px">{{
                        card.Accuracy.value
                      }}</span>
                    </p>
                    <p class="fs-13 fw-700 skyblue-text2 flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-right: 8px"
                      >
                        <path
                          d="M9.00078 16.0312C12.4602 16.0312 15.2727 13.2047 15.2727 9.73125C15.2727 8.19844 14.7242 6.77812 13.8102 5.69531L14.1898 5.31562C14.4148 5.09062 14.4148 4.73906 14.1898 4.51406C13.9648 4.28906 13.6133 4.28906 13.3883 4.51406L13.0086 4.89375C12.0664 4.07812 10.8711 3.55781 9.56328 3.44531V3.09375H9.78828C10.0977 3.09375 10.3508 2.84062 10.3508 2.53125C10.3508 2.22187 10.0977 1.96875 9.78828 1.96875H8.21328C7.90391 1.96875 7.65078 2.22187 7.65078 2.53125C7.65078 2.84062 7.90391 3.09375 8.21328 3.09375H8.43828V3.44531C7.13047 3.55781 5.93516 4.07812 4.97891 4.89375L4.59922 4.5C4.37422 4.275 4.02266 4.275 3.79766 4.5C3.57266 4.725 3.57266 5.07656 3.79766 5.30156L4.17734 5.68125C3.26328 6.77813 2.71484 8.18437 2.71484 9.71719C2.72891 13.2047 5.54141 16.0312 9.00078 16.0312ZM9.00078 4.26094C11.9961 4.26094 14.4289 6.70781 14.4289 9.73125C14.4289 12.7547 11.9961 15.1875 9.00078 15.1875C6.00547 15.1875 3.57266 12.7406 3.57266 9.73125C3.57266 6.72187 6.00547 4.26094 9.00078 4.26094Z"
                          :fill="card.AvgTime.color"
                        />
                        <path
                          d="M8.99922 13.9355C11.3195 13.9355 13.1898 12.0512 13.1898 9.7168C13.1898 7.38242 11.3055 5.49805 8.99922 5.49805C6.69297 5.49805 4.80859 7.38242 4.80859 9.7168C4.80859 12.0512 6.67891 13.9355 8.99922 13.9355ZM8.57734 8.73242V6.73555C8.57734 6.49648 8.76016 6.31367 8.99922 6.31367C9.23828 6.31367 9.42109 6.49648 9.42109 6.73555V8.73242C9.80078 8.90117 10.068 9.28086 10.068 9.7168C10.068 10.3074 9.58984 10.7855 8.99922 10.7855C8.40859 10.7855 7.93047 10.3074 7.93047 9.7168C7.93047 9.28086 8.19766 8.90117 8.57734 8.73242Z"
                          :fill="card.AvgTime.color"
                        />
                        <line
                          x1="3"
                          y1="9.5"
                          x2="1"
                          y2="9.5"
                          :stroke="card.AvgTime.color"
                          stroke-linecap="round"
                        />
                        <line
                          x1="7.5"
                          y1="15.5"
                          x2="4.5"
                          y2="15.5"
                          :stroke="card.AvgTime.color"
                          stroke-linecap="round"
                        />
                        <line
                          x1="3.5"
                          y1="12.5"
                          x2="2.5"
                          y2="12.5"
                          :stroke="card.AvgTime.color"
                          stroke-linecap="round"
                        />
                        <line
                          x1="1"
                          y1="12.5"
                          x2="0.5"
                          y2="12.5"
                          :stroke="card.AvgTime.color"
                          stroke-linecap="round"
                        />
                        <line
                          x1="3"
                          y1="15.5"
                          x2="2"
                          y2="15.5"
                          :stroke="card.AvgTime.color"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span :style="`color: ${card.AvgTime.color}`" style="margin-right: 45px">{{
                        card.AvgTime.value
                      }}</span>
                    </p>
                  </div>
                  <div
                    class="chapter_schedule_button"
                    style="padding-left: 15px; padding-bottom: 17px"
                  >
                    <div class="chapter_schedule_button1">
                      <a
                        class="schedule-yellow-btn"
                        @click="scheduleNewClass()"
                        style="margin-right: 15px; margin-bottom: 6px"
                        >Schedule Extra Class</a
                      >
                      <a
                        class="yellow-btn"
                        @click="CreateAssignment(card)"
                        style="margin-right: 15px"
                        >Create Assignment</a
                      >
                      <a class="yellow-btn" @click="CreateTest(card)">Create Test</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_section">
        <div class="leaderboard_reward-section">
          <div class="" v-if="this.avReport.length > 0">
            <h1 class="fs-18 fw-700 dark-text" style="margin-bottom: 12px">AV Report</h1>
            <Flicking
              :options="{ align: 'prev', circular: true }"
              :plugins="plugins"
              class="flex items-end card-radius"
            >
              <div v-for="(img, index) in avReport" :key="index">
                <div class="slideshow-container flex card-shadow card-radius card-border">
                  <div class="relative" style="padding: 15px; width: 100%">
                    <div class="flex flex-between items-start">
                      <h2
                        class="fw-600 fs-13"
                        style="color: #ffffff !important"
                        :title="img.ReportName"
                      >
                        {{
                          img.ReportName.length > 30
                            ? img.ReportName.slice(0, 30) + "..."
                            : img.ReportName
                        }}
                      </h2>
                      <img :src="require(`../assets/video 2.svg`)" alt="" width="24" />
                    </div>
                    <p class="fs-12 fw-600 white-text" style="opacity: 0.7">
                      {{ img.SubjectName }}
                    </p>
                    <p class="white-text fs-11 fw-400" style="opacity: 0.7">
                      {{ formatDate(img.CreatedOn) }}
                    </p>
                    <a
                      class="yellow-btn fs-10 fw-600"
                      style="
                        width: 83px;
                        height: 20px;
                        margin-top: 10px;
                        line-height: 20px;
                        display: inline-block;
                      "
                      @click="watchVideo(img.VimeoURL)"
                      >Watch Video</a
                    >
                    <img class="class-image" :src="require(`../assets/Ellipse 419.svg`)" />
                  </div>
                </div>
              </div>
            </Flicking>
          </div>
          <div
            class="schedule_event"
            v-if="Object.keys(this.batchReport.chapterWiseCards).length > 0"
          >
            <h1
              class="fs-18 fw-700 dark-text top-header"
              style="margin-top: 8px; margin-bottom: 10px"
            >
              Schedule an Event
            </h1>
            <div class="schedule-card card-border card-radius card-shadow">
              <img :src="require(`../assets/schedule-img.svg`)" alt="schedule-img" />
              <a
                class="yellow-btn"
                style="background: #f3f5fc !important; color: #3751ff !important"
                @click="scheduleNewClass()"
                >Schedule Extra Class</a
              >
              <a
                class="yellow-btn"
                style="background: #f3f5fc !important; color: #3751ff !important"
                @click="CreateNewAssignment()"
                >Create Assignment</a
              >
              <a class="yellow-btn" @click="CreateNewTest()">Create test</a>
            </div>
          </div>
          <!--Upcoming Events  -->
        </div>
      </div>
    </div>
    <Modal :show="showClassModal" :showHeader="false">
      <template v-slot:body>
        <h1 class="fs-18 fw-400 dark-text top-header center-align">
          Welcome <strong>{{ this.userData.Name }} !!</strong>
        </h1>
        <div style="display: flex; justify-content: center">
          <img class="center-align" :src="require(`../assets/school.png`)" />
        </div>
        <p class="center-align modal-select">
          Kindly select class for <b>{{ this.selectedBatch }}</b>
        </p>
        <div class="center" style="width: 450px; display: flex; justify-content: space-around">
          <button
            v-for="cls in getclass"
            :key="`cls${cls.id}`"
            class="modal-button"
            :class="{ selected: userClassId == cls.id }"
            @click="onClassChange(cls.id)"
          >
            {{ cls.className }}
          </button>
        </div>
      </template>
    </Modal>
    <Modal2 :show="showAvVideo" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <p
            @click="
              showAvVideo = false;
              avVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <iframe
          :src="avVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
  </div>
</template>

<script>
import ProfileAvatar from "vue-profile-avatar";
import moment from "moment";
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import Modal from "../components/Modal";
import Modal2 from "../components/Model2.vue";
import MobileAPI from "../Mobileapi";
import API from "../Api";

export default {
  data() {
    return {
      isClassPopupOpen: false,
      batchReport: null,
      PlotlyREport: [],
      Subjectwisedetails: [],
      SubjectiveFilterList: [],
      index: 1,
      showSubjectList: true,
      showBatchList: true,
      leaderBg: [],
      showContent: false,
      ChapterwiseCardList: [],
      chapterwiseFilterList: [],
      overviewCardList: [],
      extended: -1,
      extended1: -1,
      filteredCoins: [],
      totalFilteredCoins: [],
      avgreport: [],
      avreportlength: null,
      SearchSubjectwiseDetails: "",
      SearchChapterWiseDetails: "",
      swdheader: true,
      searchbar: false,
      batchList: [],
      subjectlist: [],
      selectedBatch: "",
      selectedSubject: "",
      selectedAffcourseId: "",
      selectedCourseId: "",
      selectedBatchId: "",
      selectedSubjectId: "",
      selectedSubjectName: "",
      affSubjectId: "",
      chapterwisedetails: true,
      chapterwisesearchdetails: false,
      UpcomingEventData: [],
      liveClassdata: [],
      avReport: [],
      coursedata: [],
      SelectedCourseList: [],
      batchData: [],
      selectedBatchList: null,
      showAvVideo: false,
      avVideoUrl: "",
      CourseId: "",
      userData: "",
      ChapterId: null,
      topicID: null,
      selectedAffCourseId: null,
      showClassModal: false,
      getclass: [],
      userClassId: "",
      updateClass: [],
      selectedClassId: "",
      getBatchForCourse: [],
      batchListData: [],
      batchFilteredData: [],
      selectedSubjectforBatch: [],
      subIndex: -1,
      chapterindex: -1,
      plugins: [
        new AutoPlay({
          duration: 10000,
          direction: "NEXT",
          stopOnHover: false,
        }),
      ],
      // isDragging: true,
    };
  },
  components: {
    ProfileAvatar,
    Flicking,
    Modal2,
    Modal,
  },
  // beforeCreate() {
  //   document.querySelector("body").setAttribute("style", "background: #E5E5E5;");
  // },

  // beforeUnmount() {
  //   document.querySelector("body").setAttribute("style", "background: #f6f1ec;");
  // },
  activated() {
    const user = this.$store.getters.user;
    this.userData = user;
    API.getBatches(
      user.AffiliationId,
      (batchresp) => {
        if (batchresp.data.length > 0) {
          this.batchData = batchresp.data;
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    API.getCourses(
      {},
      (courseresp) => {
        if (courseresp.data.length > 0) {
          this.coursedata = courseresp.data;
          this.SelectedCourseList = this.coursedata.sort((a, b) => a.courseId - b.courseId);
        }
      },
      () => {
        this.$store.dispatch("showLoader", false);
      },
    );
    const batchReportParams = JSON.parse(localStorage.getItem("BatchReportParams"));
    if (batchReportParams.teacherDashBatchId && batchReportParams.teacherDashSubId) {
      this.domainName = this.userData.DomainName;
      this.selectedBatchId = batchReportParams.teacherDashBatchId;
      this.selectedSubjectId = batchReportParams.teacherDashSubId;
      this.selectedBatch = batchReportParams.teacherDashBatchName;
      this.selectedSubjectName = batchReportParams.teacherDashSubName;
      this.selectedCourseId = batchReportParams.teacherDashCourseId;
      this.getCourseBatchSubjectData();
      const BatchDetails = {
        domainName: this.userData.DomainName,
        BatchId: batchReportParams.teacherDashBatchId,
        BatchName: batchReportParams.teacherDashBatchName,
        SubjectName: batchReportParams.teacherDashSubName,
        SubjectId: batchReportParams.teacherDashSubId,
        classId: this.selectedClassId,
        CourseId: batchReportParams.teacherDashCourseId,
      };
      localStorage.setItem("BatchReports", JSON.stringify(BatchDetails));
    } else {
      const BatchDetails = JSON.parse(localStorage.getItem("BatchReports"));
      this.selectedBatchId = BatchDetails.BatchId;
      this.selectedSubjectId = BatchDetails.SubjectId;
      this.selectedClassId = BatchDetails.classId;
      this.selectedBatch = BatchDetails.BatchName;
      this.selectedSubjectName = BatchDetails.SubjectName;
      this.selectedCourseId = BatchDetails.CourseId;
      this.getCourseBatchSubjectData();
    }
    let todayDate = new Date();
    todayDate = moment(`${todayDate}`).utc().format("YYYY-MM-DD");
    this.$store.dispatch("showLoader", true);
    API.liveclassdata(
      {
        upcoming: 1,
      },
      (response) => {
        if (response.length > 0) {
          this.liveClassdata = response;
          this.UpcomingEventData = response.filter(
            (item) => moment(item.StartTime).utc().format("YYYY-MM-DD") === todayDate,
          );
          this.scheduleClassData = this.upcomingClassData;
          this.$store.dispatch("showLoader", false);
        }
        this.$store.dispatch("showLoader", false);
      },
    );
    this.swdheader = true;
    this.searchbar = false;
    const avdata = {
      CourseId: this.selectedCourseId,
      BatchId: this.selectedBatchId,
      SubjectId: this.selectedSubjectId,
    };
    MobileAPI.getAvReportdata(avdata, (response) => {
      this.avReport = response.data.filter((obj) => obj.CardType !== "AffiliationReport");
    });
    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
    ];
  },
  computed: {
    SearchSubWiseList() {
      let templist = this.Subjectwisedetails;
      if (this.SearchSubjectwiseDetails !== "" && this.SearchSubjectwiseDetails) {
        templist = templist.filter(
          (item) =>
            item.userName.toLowerCase().indexOf(this.SearchSubjectwiseDetails.toLowerCase()) >= 0,
        );
      } else {
        templist = this.Subjectwisedetails;
        this.onClickOutside();
      }
      return templist;
    },
    SearchChapterWiseList() {
      let templist = this.ChapterwiseCardList ? this.ChapterwiseCardList : "-";
      if (this.SearchChapterWiseDetails !== "" && this.SearchChapterWiseDetails) {
        templist = templist.filter(
          (item) =>
            item.chapterName.toLowerCase().indexOf(this.SearchChapterWiseDetails.toLowerCase()) >=
            0,
        );
      } else {
        templist = this.ChapterwiseCardList;
        this.onClickOustside1();
      }
      return templist;
    },
    filterCourse() {
      return [...new Map(this.SelectedCourseList.map((item) => [item.courseId, item])).values()];
    },
    filterBatch() {
      return [...new Map(this.selectedBatchList.map((item) => [item.BatchName, item])).values()];
    },
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD MMM YYYY");
      }
      return "";
    },
    formatTime1(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("hh:mm A");
      }
      return "";
    },
    getCourseBatchSubjectData() {
      MobileAPI.getCourseBatchSubject(
        {
          AffiliationId: Number(this.userData.AffiliationId),
        },
        (resp) => {
          this.batchListData = resp.Data;
          for (let i = 0; i < this.batchListData.length; i += 1) {
            if (this.batchListData[i].CourseId === Number(this.selectedCourseId)) {
              this.batchFilteredData = this.batchListData[i].BatchDetails;
            }
          }
          for (let i = 0; i < this.batchFilteredData.length; i += 1) {
            if (this.batchFilteredData[i].CenterId === Number(this.selectedBatchId)) {
              this.selectedSubjectforBatch = this.batchFilteredData[i].subjectDetails[0];
              this.selectedClassId = this.batchFilteredData[i].ClassId;
            }
          }
          if (this.selectedClassId === null) {
            MobileAPI.getClassforbatch(
              {
                BatchId: this.selectedBatchId,
              },
              (classResponse) => {
                this.getclass = classResponse.data;
                if (classResponse.data.length > 1) {
                  this.showClassModal = true;
                } else {
                  this.selectedClassId = classResponse.data[0].id;
                }
              },
            );
          } else {
            const data = {
              batchId: this.selectedBatchId,
              subjectId: this.selectedSubjectId,
              classId: this.selectedClassId,
            };
            this.$store.dispatch("showLoader", true);
            MobileAPI.getbatchreport(data, (response) => {
              this.batchReport = response.data;
              this.$store.dispatch("showLoader", false);
              if (
                this.batchReport.chapterWiseCards !== null &&
                this.batchReport.overviewCards.length > 0 &&
                this.batchReport.studentWiseCards
              ) {
                this.Subjectwisedetails = response.data.studentWiseCards.cards;
                this.SubjectiveFilterList = response.data.studentWiseCards.filters;
                this.overviewCardList = response.data.overviewCards;
                this.ChapterwiseCardList = response.data.chapterWiseCards.cards;
                this.chapterwiseFilterList = response.data.chapterWiseCards.filters;
                //   this.filterSubjectList(Object.values(this.SubjectiveFilterList)[0], Object.keys(this.SubjectiveFilterList)[0]);
                if (this.SubjectiveFilterList != null) {
                  const defaultSubjectCard = Object.values(this.SubjectiveFilterList).filter(
                    (a) => a.isDefault,
                  );
                  if (defaultSubjectCard != null) {
                    this.subIndex = Object.values(this.SubjectiveFilterList).findIndex(
                      (a) => a.isDefault,
                    );
                    this.filterSubjectList(defaultSubjectCard[0], this.subIndex);
                  }
                }
                if (this.chapterwiseFilterList != null) {
                  const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                    (a) => a.isDefault,
                  );
                  if (defaultCard != null) {
                    this.chapterindex = Object.values(this.chapterwiseFilterList).findIndex(
                      (a) => a.isDefault,
                    );
                    this.filterChapterCards(defaultCard[0], this.chapterindex);
                  }
                }
              } else {
                this.$store.dispatch("showLoader", false);
                this.Subjectwisedetails = [];
                this.SubjectiveFilterList = [];
                this.overviewCardList = [];
                this.ChapterwiseCardList = [];
                this.chapterwiseFilterList = [];
              }
            });
          }
        },
      );
    },
    onClassChange(classId) {
      this.selectedClassId = classId;
      const Cdata = {
        ClassId: this.selectedClassId,
        BatchId: this.selectedBatchId,
      };
      MobileAPI.UpdateClassforStudent(Cdata, (resp) => {
        if (resp.responseCode === 200) {
          this.showClassModal = false;
          if (this.selectedSubjectId !== "") {
            const data = {
              batchId: this.selectedBatchId,
              subjectId: this.selectedSubjectId,
              classId: this.selectedClassId,
            };
            this.$store.dispatch("showLoader", true);
            MobileAPI.getbatchreport(data, (response) => {
              this.batchReport = response.data;
              this.$store.dispatch("showLoader", false);
              if (
                this.batchReport.chapterWiseCards !== null &&
                this.batchReport.overviewCards.length > 0 &&
                this.batchReport.studentWiseCards
              ) {
                this.Subjectwisedetails = response.data.studentWiseCards.cards;
                this.SubjectiveFilterList = response.data.studentWiseCards.filters;
                this.overviewCardList = response.data.overviewCards;
                this.ChapterwiseCardList = response.data.chapterWiseCards.cards;
                this.chapterwiseFilterList = response.data.chapterWiseCards.filters;
                // this.filterSubjectList(Object.values(this.SubjectiveFilterList)[0], Object.keys(this.SubjectiveFilterList)[0]);
                if (this.SubjectiveFilterList != null) {
                  const defaultSubjectCard = Object.values(this.SubjectiveFilterList).filter(
                    (a) => a.isDefault,
                  );
                  if (defaultSubjectCard != null) {
                    this.subIndex = Object.values(this.SubjectiveFilterList).findIndex(
                      (a) => a.isDefault,
                    );
                    this.filterSubjectList(defaultSubjectCard[0], this.subIndex);
                  }
                }
                if (this.chapterwiseFilterList != null) {
                  const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                    (a) => a.isDefault,
                  );
                  if (defaultCard != null) {
                    this.chapterindex = Object.values(this.chapterwiseFilterList).findIndex(
                      (a) => a.isDefault,
                    );
                    this.filterChapterCards(defaultCard[0], this.chapterindex);
                  }
                }
                this.chapterwiseFilterList = response.data.chapterWiseCards.filters;
              } else {
                this.Subjectwisedetails = [];
                this.SubjectiveFilterList = [];
                this.overviewCardList = [];
                this.ChapterwiseCardList = [];
                this.$store.dispatch("showLoader", false);
              }
            });
          }
        }
      });
    },
    expand(index) {
      if (this.extended === index) {
        this.extended = -1;
      } else {
        this.extended = index;
      }
    },
    expand_less(index) {
      if (this.extended === index) {
        this.extended = -1;
      } else {
        this.extended = index;
      }
    },
    expand1(index, ChapterId) {
      if (this.extended1 === index) {
        this.extended1 = -1;
      } else {
        this.extended1 = index;
        this.ChapterId = ChapterId;
        const data = {
          SubjectId: this.selectedSubjectId,
          ChapterId: this.ChapterId,
        };
        MobileAPI.getTopicId(data, (response) => {
          this.topicID = response.data.TopicId;
        });
      }
    },
    expand_less1(index) {
      if (this.extended1 === index) {
        this.extended1 = -1;
      } else {
        this.extended1 = index;
      }
    },
    endTime(startTime, endTime) {
      return new Date(
        new Date(`1970/01/01 ${startTime.substring(11, 16)}`).getTime() + endTime * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },
    searchBar() {
      this.swdheader = false;
      this.searchbar = true;
    },
    searchChapter() {
      this.chapterwisedetails = false;
      this.chapterwisesearchdetails = true;
    },
    onClickOutside() {
      this.swdheader = true;
      this.searchbar = false;
      this.SearchSubjectwiseDetails = "";
    },
    onClickOustside1() {
      this.chapterwisedetails = true;
      this.chapterwisesearchdetails = false;
      this.SearchChapterWiseDetails = "";
    },
    onClickSelectBatch() {
      this.showBatchList = true;
    },
    onClickSeledSubject() {
      this.showSubjectList = true;
    },
    onselectBatch(BatchName, CourseId, BatchId) {
      this.selectedSubjectId = "";
      this.selectedSubjectName = "";
      this.selectedBatch = BatchName;
      this.showBatchList = !this.showBatchList;
      this.selectedCourseId = CourseId;
      this.selectedBatchId = BatchId;
      this.selectedSubjectId = "";
      for (let i = 0; i < this.batchFilteredData.length; i += 1) {
        if (this.batchFilteredData[i].CenterId === Number(this.selectedBatchId)) {
          this.selectedSubjectforBatch = this.batchFilteredData[i].subjectDetails[0];
          this.selectedClassId = this.batchFilteredData[i].ClassId;
        }
      }
      if (this.selectedClassId === null) {
        MobileAPI.getClassforbatch(
          {
            BatchId: this.selectedBatchId,
          },
          (classResponse) => {
            this.getclass = classResponse.data;
            if (classResponse.data.length > 1) {
              this.showClassModal = true;
            } else {
              this.selectedClassId = classResponse.data[0].id;
            }
          },
        );
      }
    },
    onselectSubject(subjectId, SubjectName, affSubjectId) {
      this.selectedSubjectId = subjectId;
      this.selectedSubjectName = SubjectName;
      this.affSubjectId = affSubjectId;
      localStorage.removeItem("BatchReports");
      this.showSubjectList = !this.showSubjectList;
      const BatchDetails = {
        domainName: this.userData.DomainName,
        BatchId: this.selectedBatchId,
        BatchName: this.selectedBatch,
        SubjectName: this.selectedSubjectName,
        SubjectId: this.selectedSubjectId,
        classId: this.selectedClassId,
        CourseId: this.selectedCourseId,
      };
      localStorage.setItem("BatchReports", JSON.stringify(BatchDetails));
      this.getCourseBatchSubjectData();
      const avdata = {
        CourseId: this.selectedCourseId,
        BatchId: this.selectedBatchId,
        SubjectId: this.selectedSubjectId,
      };
      MobileAPI.getAvReportdata(avdata, (response) => {
        this.avReport = response.data;
      });
    },
    startClass(data) {
      if (data.ZoomStartUrl == null) {
        const serverid = data.ServerId > 0 ? data.ServerId : 1;
        const url = `http://api.speedslabs.in/api_enc.php?meetingid=${data.Meetingid}&meetingname=${data.Title}&moderatorname=${data.FacultyName}&server=${serverid}&logouturl=https://speedlabsstaging.azurewebsites.net/AffiliationGetLiveClasses.aspx&createandjoin=Start`;
        window.open(url, "_blank");
      } else {
        let newUrl = "";
        MobileAPI.getZoomUrl(Number(data.Id), (response) => {
          newUrl = response;
          window.open(newUrl, "_blank");
        });
      }
    },
    getTimeDiff(startDate, duration, DisplayStartTime) {
      let status = "startBtnDeactive";
      const currDate = new Date();
      const minsToAdd = duration;
      const minsToMin = -15;
      const time = DisplayStartTime.substring(11, 16);
      const endTime = new Date(
        new Date(`1970/01/01 ${time}`).getTime() + minsToAdd * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const start = new Date(
        new Date(`1970/01/01 ${time}`).getTime() + minsToMin * 60000,
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const date = moment(currDate).format("DD/MM/YYYY");
      const startDates = moment(startDate).utcOffset("-00:00").format("DD/MM/YYYY");
      const currTime = moment(currDate).format("HH:mm");
      if (startDates === date) {
        if (start <= currTime && currTime <= endTime) {
          status = "";
        } else {
          status = "startBtnDeactive";
        }
      }
      return status;
    },
    CreateTest(card) {
      MobileAPI.getTopicId(
        {
          SubjectId: this.selectedSubId,
          ChapterId: card.chapterId,
        },
        (topicResponse) => {
          const createInstituteTestParams = {
            courseIdProps: this.selectedCourseId,
            subIdProps: this.selectedSubjectId,
            chapterIdProps: card.chapterId,
            topicIdProps: topicResponse.data.TopicId,
            classIdProps: this.selectedClassId,
            batchIdProps: this.selectedBatchId,
          };
          localStorage.setItem(
            "CreateInstituteTestParams",
            JSON.stringify(createInstituteTestParams),
          );
          this.$router.push({ name: "CreateInstituteTest", params: createInstituteTestParams });
        },
      );
    },
    CreateNewTest() {
      const createInstituteTestParams = {
        courseIdProps: this.selectedCourseId,
        subIdProps: this.selectedSubjectId,
        classIdProps: this.selectedClassId,
        batchIdProps: this.selectedBatchId,
      };
      localStorage.setItem("CreateInstituteTestParams", JSON.stringify(createInstituteTestParams));
      this.$router.push({ name: "CreateInstituteTest", params: createInstituteTestParams });
    },
    CreateAssignment() {
      this.$router.push({ name: "CreateAssignment" });
    },
    CreateNewAssignment() {
      this.$router.push({ name: "CreateAssignment" });
    },
    scheduleNewClass() {
      this.$router.push({ path: "/affiliation/create-live-class" });
    },
    filterChapterCards(chapterFilter, index) {
      const sortArray = (arr1, arr2) => {
        arr2.sort((a, b) => {
          const aKey = a.courseChapterId;
          const bKey = b.courseChapterId;
          return arr1.indexOf(aKey) - arr1.indexOf(bKey);
        });
      };
      sortArray(chapterFilter.order, this.ChapterwiseCardList);
      Object.values(this.chapterwiseFilterList).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.isDefault = false;
      });
      Object.values(this.chapterwiseFilterList)[index].isDefault = true;
    },
    filterSubjectList(subjectFilter, index) {
      const sortArray = (arr1, arr2) => {
        arr2.sort((a, b) => {
          const aKey = a.userId;
          const bKey = b.userId;
          return arr1.indexOf(aKey) - arr1.indexOf(bKey);
        });
      };
      sortArray(subjectFilter.order, this.Subjectwisedetails);
      Object.values(this.SubjectiveFilterList).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.isDefault = false;
      });
      Object.values(this.SubjectiveFilterList)[index].isDefault = true;
    },
    watchVideo(videoUrl) {
      this.avVideoUrl = videoUrl;
      this.showAvVideo = true;
    },
    scrollX1(e) {
      this.$refs.scroll_container1.scrollLeft += e.deltaY;
    },
    scrollX2(e) {
      this.$refs.scroll_container2.scrollLeft += e.deltaY;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

.subject-coverage {
  font-family: "Open Sans" !important;
  margin-top: 25px;
}

/* Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /*Standard */
  margin: 0;
}

@font-face {
  font-family: PackMan;
  src: url(../font/pac-font.ttf);
}

* {
  box-sizing: border-box;
}

.dark-text {
  color: #333333 !important;
}

.black-text2 {
  color: #242c3c;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.skyblue-text2 {
  color: #5c8eff !important;
}

.purple-text {
  color: #642c90 !important;
}

.purple-text2 {
  color: #9470c1 !important;
}

.orange-text {
  color: #f59e00 !important;
}

.light-gray-text {
  color: #666666;
}

.white-bgc {
  background: #fff;
}

.orange-bgc {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.lightPink-bgc {
  background: #ffecdb;
}

.lightGreen-bgc {
  background: #ebffe7;
}

.test-bgc {
  background: linear-gradient(98.34deg, #e9ccff 11.31%, #f4e6ff 88.13%);
}

.purple-bgc {
  background: #642c90;
}

.card-border {
  border: 1px solid #e3e3e3;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-radius {
  border-radius: 5px;
}

.card-radius-2 {
  border-radius: 6px;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.block {
  display: block;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.max-content {
  width: max-content;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.m-0 {
  margin: 0;
}

.mt-31 {
  margin-top: 31px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-24 {
  margin-bottom: 24px;
}

.w-105 {
  width: 105px;
}

.w-69 {
  width: 69px;
}

.w-75 {
  width: 75px;
}

.p-15 {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 0px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.select-box.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
  padding: 0 10px 0 10px;
}

.schedule-yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
  padding: 0 10px 0 10px;
  position: relative;
}

.schedule-yellow-btn .schedule_img {
  position: absolute;
  right: -10.11px;
  top: -10.5px;
}

.gray-btn {
  height: 21px;
  background: #f8f8f8;
  border: 1px solid #d6daf7;
  border-radius: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  opacity: 0.65;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;
}

/*Middle section*/
.middle-section {
  max-width: 565px;
  min-width: 300px;
  width: 100%;
  margin: 0 20px;
}

/* .middle-Chapter-card-box {
    padding: 15px;
} */

.chapter_wise_card_scroll {
  max-height: 455px;
}

.chapter_wise_card .card-name {
  padding-left: 15px;
  padding-top: 13px;
  padding-bottom: 13px;
  width: 154px;
  margin-right: 3px;
  /* margin-right: 93px; */
}

.chapter_wise_card .border {
  border-left: 3px solid orangered;
  height: 17.71px;
  /* border-radius: 0 2px 2px 0; */
}

.middle-card-box {
  padding: 15px;
  margin-bottom: 25px;
  width: 100%;
}

.Chapter_wise_Analysis {
  margin: 15px 0 22px 15px;
}

.chapter_wise_card {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  /* margin-left: 15px;
    margin-right: 15px; */
  margin-bottom: 15px;
}

.chapter_wise_card .chapter_performance_card {
  background-color: #ddfffd;
  width: 48px;
  height: 18px;
  color: #13a8ac;
  text-align: center;
  border-radius: 3px;
  margin: 12px 12px 12px 0;
  margin-left: 4px;
}

.chapter_wise_card .Chapter_card {
  display: flex;
  justify-content: space-between;
}

.chapter_wise_card .Chapter_card .Chapter_name {
  position: relative;
}

.chapter_wise_card .Chapter_card .Chapter_name::before {
  position: absolute;
  content: "";
  left: 0;
  background: #3751ff;
  margin-top: -4.15px;
  border-radius: 0px 2px 2px 0px;
  width: 3px;
  height: 17.71px;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  margin-top: 11px;
}

.card-box {
  position: relative;
  max-width: 168px;
  height: 90px;
  /* background: #FFFAF0;
    border: 1px solid #F5EEE0;
    color: #EE9B05; */
  background: #faf5ff;
  border: 1px solid #e3e8f2;
  color: #9470c1;
  padding: 10px 15px 15px;
}

.card-box h3 {
  margin-bottom: 9px;
}

.card-box::after {
  /*position: absolute;
    content: "";
    right: -70%;
    bottom: -45%;
    background: url('../assets/bookmark-page\ 1.svg') no-repeat;
    z-index: 10;
    width: 100%;
    height: 100%;*/
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: url("../assets/bookmark-page\ 1.svg") no-repeat;
  /* background: url('../assets/success\ 1.svg') no-repeat; */
  z-index: 10;
  width: 100%;
  height: 100%;
  background-position: 95% 83% !important;
  z-index: 0;
}

.card-box:nth-child(2) {
  background: #f2f6ff;
  border: 1px solid #e3e8f2;
  color: #5c8eff;
}

.card-box:nth-child(2):after {
  background: url("../assets/QuestionAnswer\ 2.svg") no-repeat;
}

.card-box:nth-child(3) {
  background: #f4faff;
  border: 1px solid #dde9f2;
  color: #427ca9;
}

.card-box:nth-child(3):after {
  background: url("../assets/hourglass\ 4.svg") no-repeat;
}

.card-box:nth-child(4) {
  /* background: #FAF5FF;
    border: 1px solid #E3E8F2;
    color: #9470C1; */
  background: #fffaf0;
  border: 1px solid #f5eee0;
  color: #ee9b05;
}

.card-box:nth-child(4):after {
  background: url("../assets/success\ 1.svg") no-repeat;
  /* background: url('../assets/bookmark-page\ 1.svg') no-repeat; */
}

.card-box:nth-child(5) {
  background: #fff4f5;
  border: 1px solid #f1e5e6;
  color: #ef727a;
}

.card-box:nth-child(5):after {
  background: url("../assets/aim\ 1.svg") no-repeat;
}

.card-box:nth-child(6) {
  background: #f3fffc;
  border: 1px solid #dceeea;
  color: #36af8d;
}

.card-box:nth-child(6):after {
  background: url("../assets/Speed2.svg") no-repeat;
}

/* Custom CSS */
/* Left Section */
.border_line {
  border-right: 1px solid #dedede;
}

.SWD-body {
  width: 100%;
  max-height: calc(100vh - 200px);
}

.left-section {
  /* width: 487px; */
  /* min-width: 310px; */
  padding: 15px 20px;
  height: max-content;
  margin-bottom: 10px;
}

.secondary-header {
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #642c90;
}

.Chapter_wise_Analysis .secondary-header {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #642c90;
}

.chapter_wise_performance_details1 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-top: 18px;
  padding-bottom: 17px;
}

.subject-select {
  max-width: 335px;
  height: 67px;
  position: relative;
  margin-top: 12px;
  padding: 10px 16px;
}

.subject-select .subject-img {
  position: absolute;
  right: 17px;
  top: 13px;
  opacity: 0.1;
  width: 55px;
}

.subject-list {
  width: 100%;
  max-height: 229px;
  position: absolute;
  left: 0;
  z-index: 20;
  top: -1px;
  background: #ffffff;
  border: 1px solid #333333;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.15);
  border-radius: 5px;
  padding: 10px 0;
}

.subject-list a {
  padding-left: 15px;
  margin-bottom: 10px;
}

.sublist p:first-child(1) {
  margin-top: 10px;
}

.sublist p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 8px 15px;
}

.sublist p:hover {
  background: #f6f8fb;
}

.chapter-wise-detail {
  margin-top: 22px;
  margin-bottom: 20px;
}

.chapter-wise-detail h2 {
  margin-bottom: 10px;
}

.chapter-wise-detail .gray-btn.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.Chapter_wise_Analysis .gray-btn.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.Student-wise-details {
  width: 335px;
  /* min-height: calc(100vh - 200px); */
  max-height: 617px;
  margin-top: 9px;
  overflow: hidden;
}

.chapter-wise-card {
  padding: 10px 15px;
}

.progress-value {
  margin-top: 14px;
  margin-bottom: 8px;
}

.progress1 {
  position: relative;
  height: 6px;
  width: 113px;
  background: #f2f3f8;
  border-radius: 30px;
  /* display: flex;
    justify-content: space-between; */
}

.progress2 {
  position: relative;
  height: 6px;
  width: 126px;
  background: #f2f3f8;
  border-radius: 30px;
  /* display: flex;
    justify-content: space-between; */
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: #ee9b05;
}

.Student-wise-details {
  /* border: 1px solid red; */
  box-sizing: border-box;
  width: 335px;
  /* max-height: 617px; */
  left: 132px;
  top: 295px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  /* min-height: calc(100vh - 200px); */
  max-height: 617px;
  margin-top: 9px;
  overflow: hidden;
}

.Student-wise-details .SWD-Header {
  display: flex;
  justify-content: space-between;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  height: 39px;
  text-align: left;
  color: #333333;
  background: #fafafa;
  align-items: center;
  border-radius: 5px 5px 0 0;
  /* border-bottom: 1px solid #e0e4f0; */
}

.overallExpertise {
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#search {
  /* justify-content: center; */
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid #e0e4f0;
  width: 27px;
  height: 27px;
  /* align-items: center; */
  background-image: url("../assets/search\ 1.svg");
  /* background-size: cover; */
  /* text-align: center;
    line-height: 27px; */
  background-position: center;
}

.Student-wise-details .SWD-body .SWD-body-content {
  border-top: 1px solid #e0e4f0;
}

.SWD-body .SWD-body-content {
  /* display: flex !important; */
  padding: 10px 10px 10px 0;
  justify-content: space-around;
  align-items: center;
}

.sub-name {
  /* position: absolute;
    content: ""; */
  /* top: 5px;
    left: 15px; */
  /* background: #642C90; */
  border-left: orangered;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 26px;
}

.chapter_schedule_button1 {
  display: flex;
  flex-wrap: wrap;
}

/*Right Section */
.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar1.green {
  background: linear-gradient(180deg, #16bcb2 0%, #16bcb2 100%);
}

/*Leaderboard */
.right_section {
  margin-top: 25px;
}

.leaderboard_reward-section {
  min-width: 217px;
  max-width: 217px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.leaderboard .leader-body {
  max-height: 390px;
}

.leaderboard .leader-body.leader-details .leader-top {
  padding: 6px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body.leader-details .leader-top:first-child {
  padding-top: 0;
}

/*
.leaderboard .leader-body .leader-top {
    padding: 10px 0px;
    border-bottom: 1px solid #E0E4F0;
}*/

.leaderboard .leader-body .leader-top:first-child {
  padding-top: 0px;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
  padding: 10px 0px 0px;
}

.leader-para {
  line-height: 20px;
  margin-right: 16px;
  width: 50px;
}

.leader-card {
  padding: 22px 15px;
  position: relative;
  max-height: 433px;
}

/* .leaderboard_reward-section {
    min-width: 216px;
    margin-right: 15px;
} */

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  background: #fc8686 !important;
  color: white !important;
  margin-right: 10px;
}

/* .leaderboard_reward-section {
    min-width: 216px;
} */

.leaderboard {
  margin-top: 18px;
}

.leaderboard-content {
  padding: 16px 15px 4px;
  max-height: 470px;
  height: 100%;
}

.leaderboard .leader-body .leader-top {
  padding: 8px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
}

.leader-para {
  line-height: 20px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.schedule-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 15px 15px 15px;
  background-color: white;
  margin-top: 10px;
  margin-bottom: 20px;
}

.schedule-card img {
  width: 144px;
  height: 101px;
}

.schedule-card .yellow-btn {
  width: 187px !important;
  height: 28px !important;
  margin-top: 10px;
  border-radius: 5px;
  line-height: 28px;
}

.schedule-card .yellow-btn:nth-child(2) {
  background: #f3f5fc !important;
  color: #3751ff !important;
}

.schedule-card .yellow-btn:last-child {
  background: #3751ff !important;
}

.upcoming-event {
  max-height: 300px;
}

.event-card:first-child {
  margin-top: 0;
}

.event-card img {
  width: 27px;
  height: 27px;
  margin-right: 9.5px;
}

.event-card {
  width: 216px;
  height: 64px;
  padding: 10px 15px;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #e0e4f0;
  transition: 0.3s all ease;
}

.event-card:hover {
  border: 1px solid #000000;
}

/*Coverage report*/
.slideshow-container {
  min-width: 100%;
  height: 203px;
  background: #5a5fe6;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
  min-width: auto;
  padding: 2px 0;
  height: auto;
  overflow: hidden;
}

.class-image {
  position: absolute;
  right: -10px;
  top: 60px;
  z-index: 0;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.startBtnDeactive {
  pointer-events: none;
  color: #80808078 !important;
}

.modal-button.selected {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.modal-button {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  cursor: pointer;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.subjectWiseDetails_subdata {
  font-size: 13px;
  font-weight: 700;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

/*Media Query*/
@media screen and (max-width: 330px) {
  .selectedBatch {
    width: 85px !important;
  }
}

@media screen and (max-width: 400px) {
  .subject-select {
    max-width: 100%;
    height: 80px;
    position: relative;
    margin-top: 12px;
    padding: 10px 16px;
  }
}

@media screen and (max-width: 1410px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media screen and (max-width: 1330px){
 .chapter_schedule_button1{
     flex-direction: column;
     align-items: center;

}
.chapter_schedule_button1 a{
    padding-bottom: 10px;
}

} */

@media screen and (max-width: 992px) {
  .Batch-Report {
    flex-direction: column;
    align-items: center;
  }

  .subject-coverage {
    flex-direction: column;
    align-items: center;
  }

  /* .left-section {
        max-width: 565px;
        width: 95%;
        margin-bottom: 15px;
    } */

  .middle-section {
    max-width: 565px;
    min-width: 300px;
    width: 100%;
    margin: 0 20px;
  }

  .middle-section {
    width: 95%;
  }

  .middle-card-box {
    padding: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .card-box {
    position: relative;
    max-width: 168px;
    height: 90px;
    background: #faf5ff;
    padding: 10px 15px 15px;
    color: #f5eee0;
  }

  .card-box h3 {
    margin-bottom: 9px;
  }

  .card-content {
    grid-template-columns: repeat(3, 1fr);
  }

  .Student-wise-details {
    width: 100% !important;
  }

  .left-section {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .videoModal {
    width: 100% !important;
    height: auto !important;
    padding: 10px 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

.reward-header {
  height: 69px;
  background: #75447c;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  padding: 15px;
}

.reward-body {
  padding: 10px 15px 15px;
}

.reward-list {
  margin-bottom: 10px;
}

.reward-list li {
  border-bottom: 1px solid #f7f7f7;
  padding: 5px 0;
}

.reward-list li img {
  position: relative;
  top: 3px;
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  box-shadow: none;
}

/* input[type=text]:not(.browser-default){ */
#search2 {
  border-bottom: none;
  padding: 0 16px;
  margin: 0;
  height: 39px;
  /* background-repeat: no-repeat;
    background-image: url('../assets/search\ 1.svg'); */
}

input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: none;
}

.flicking-camera > * {
  width: 100% !important;
}
</style>
